<template>
  <v-card>
    <v-card-title class="text-body-1 font-weight-bold">Please do not back and refresh the page. Redirecting ...</v-card-title>
  </v-card>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';

export default {
  methods: {
    ...mapActions('config', ['getAdminOption']),
    ...mapMutations('auth', ['SET_TOKEN']),
    ...mapMutations('customer', ['SET_CUSTOMER']),
  },
  async mounted() {
    if (this.isLoggedIn) {
      this.$router.push({ name: 'checkout' });
    }

    if (!this.$route.query) {
      this.$router.push({ name: 'login' });
    }

    const customer = {
      avatar: this.$route.query.avatar,
      email: this.$route.query.email,
      firstName: this.$route.query.firstName,
      lastName: this.$route.query.lastName,
      phoneNo: this.$route.query.phoneNo,
    };
    this.SET_TOKEN(this.$route.query.token);
    this.SET_CUSTOMER(customer);

    setTimeout(() => {
      const iframe = document.getElementById('sso').contentWindow;
      iframe.postMessage({
        action: 'login',
        value: {
          token: this.$route.query.token,
          customer,
        },
      }, '*');
      
      this.$router.push({ name: 'login' });
    }, 1000);
  },
};
</script>

<style>
@media screen and (max-width: 375px) {
  .loginscreen h5 {
    font-size: 16px;
  }
  .loginscreen .smallhelpertext {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 360px) {
  .loginscreen .medhelpertext {
    font-size: 12px;
  }
  .loginscreen .smallhelpertext {
    font-size: 11px !important;
  }
}
@media screen and (max-width: 320px) {
  .loginscreen .smallhelpertext {
    font-size: 9px !important;
  }
  .loginscreen h5 {
    font-size: 16px;
  }
  .loginscreen .v-text-field__slot {
    font-size: 13px;
  }
}
.loginscreen .smallhelpertext {
  font-size: 13px;
}
.mobilenmberfield input[placeholder='Mobile Number'] {
  padding-left: 10px;
}
.endbtn .v-btn__content {
  color: white !important;
  font-size: 18px;
}
</style>
